function setCookie(cname, cvalue, exdays, path) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires + ";" + "path=" + path;
}

function cookies() {
    const closeBtn = document.querySelector('.js-cookie-close');
    if (closeBtn) {
        closeBtn.addEventListener('click', function() {
            setCookie('accept-cookies', 1, 365, '/');
            document.querySelector('.js-cookie').remove();
        });
    }
}

function poll() {
    const poll = document.querySelector('form[name="poll"]');

    if (poll) {
        const answer = poll.querySelector('#poll_answer');
        const other = poll.querySelector('[name="poll[other]"]');

        answer.addEventListener('change', update);
        answer.dispatchEvent(new Event('change'));

        function update() {
            const checked = answer.querySelector('input:checked').value;
            other.disabled = checked !== 'other'
        }
    }
}

function contact() {
    const form = document.querySelector('form[name="contact"]');
    if (!form) {
        return;
    }

    form.addEventListener('submit', function(e) {
        e.preventDefault();
        const data = new FormData(form);
        fetch(form.action, {
            method: form.method,
            body: data
        }).then(response => {
            if (response.ok) {
                form.reset();
                document.querySelector('.contact-form').classList.add('form-success');
                form.querySelector('.form-success').style.display = 'block';
            } else {
                document.querySelector('.contact-form').classList.add('form-error');
                form.querySelector('.form-error').style.display = 'block';
            }
        });
    });
}

document.addEventListener('DOMContentLoaded', function() {
    cookies();
    poll();
    contact();
});
